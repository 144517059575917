/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { usePreviewWidget } from '@/components/preview/PreviewWidgetFrame';
import { useBreadcrumbTrail } from '@/data/Content/BreadcrumbTrail';
import { useExtraRequestParameters } from '@/data/Content/_ExtraRequestParameters';
import { useNextRouter } from '@/data/Content/_NextRouter';
import { useSettings } from '@/data/Settings';
import { usePageDataFromId } from '@/data/_PageDataFromId';
import { MARKETING_SPOT_DATA_TYPE } from '@/data/constants/marketing';
import { EventsContext } from '@/data/context/events';
import { ID } from '@/data/types/Basic';
import { ESpotActivityContainer } from '@/data/types/Marketing';
import { getClientSideCommon } from '@/data/utils/getClientSideCommon';
import { getESpotBaseData } from '@/data/utils/getESpotBaseData';
import { getESpotParams } from '@/data/utils/getESpotQueryParams';
import { expand, shrink } from '@/data/utils/keyUtil';
import { transactionsSpot } from 'integration/generated/transactions';
import { ComIbmCommerceRestMarketingHandlerESpotDataHandlerESpotContainer as ESpotContainer } from 'integration/generated/transactions/data-contracts';
import { RequestParams } from 'integration/generated/transactions/http-client';
import { useContext, useEffect, useMemo } from 'react';
import useSWR from 'swr';

const DATA_KEY = 'ESpotDataFromCategory';

export const fetcher =
	(pub: boolean) =>
	/**
	 * Data fetcher for E Marketing Spot
	 * @param storeId the store Id.
	 * @param name the eSpot name.
	 * @param query the request query.
	 * @param params The RequestParams, it contains all the info that a request needed except for 'body' | 'method' | 'query' | 'path'.
	 *                                  we are using it to send cookie header.
	 * @returns Fetched E Marketing Spot data.
	 */
	async (
		storeId: string,
		name: any,
		categoryId: string,
		query: Record<string, string | boolean>,
		params: RequestParams
	) => {
		if (pub) {
			return await transactionsSpot(pub).espotCategoryDetail(
				name,
				storeId,
				categoryId,
				query,
				params
			);
		} else {
			try {
				return await transactionsSpot(pub).espotCategoryDetail(
					name,
					storeId,
					categoryId,
					query,
					params
				);
			} catch (e) {
				console.log(e);
				return undefined;
			}
		}
	};

export type ESpotContainerType = ESpotContainer;

export const useESpotDataFromNameByCategory = (
	emsName: ID,
	categoryId: string | undefined,
	trackEvents = true
) => {
	const { onPromotionView } = useContext(EventsContext);
	const router = useNextRouter();
	const { settings } = useSettings();
	const { breadcrumb } = useBreadcrumbTrail();
	const { storeId, langId, defaultCatalogId: catalogId } = getClientSideCommon(settings, router);
	const { setESpotData } = usePreviewWidget();
	const { query } = router;
	const params = useExtraRequestParameters();
	const { data: pageData } = usePageDataFromId();
	const queryBase = useMemo(
		() => ({ catalogId, DM_ReturnCatalogGroupId: true, DM_FilterResults: false, langId }),
		[catalogId, langId]
	);
	const { data, error } = useSWR(
		storeId && categoryId
			? [
					shrink({
						storeId,
						...getESpotParams({ pageData, query, emsName, queryBase, breadcrumb }),
					}),
					DATA_KEY,
			  ]
			: null,
		async ([props]) => {
			const expanded = expand<Record<string, any>>(props);
			return fetcher(true)(
				expanded.storeId,
				expanded.emsName,
				categoryId as string,
				expanded.query,
				params
			);
		},
		{ revalidateOnMount: true }
	);

	useEffect(() => {
		setESpotData && setESpotData(data?.MarketingSpotData);
	}, [data, setESpotData]);

	useEffect(() => {
		if (trackEvents) {
			const all = getESpotBaseData(data);
			all?.forEach((spot) => {
				if (MARKETING_SPOT_DATA_TYPE.CONTENT === spot?.baseMarketingSpotDataType) {
					const activity = spot as Required<ESpotActivityContainer>;
					onPromotionView({ gtm: { activity, settings } });
				}
			});
		}
	}, [data]); // eslint-disable-line react-hooks/exhaustive-deps

	return {
		data,
		loading: !error && !data,
		error,
	};
};
