import Cookies from 'cookies';
import { GetServerSidePropsContext } from 'next';
import { Cache } from '@/data/types/Cache';
import { PARAMETER_COOKIES } from '@/data/constants/cookies';
import { useSettings } from '@/data/Settings';
import { customMarketing } from 'integration/generated/custom';
import useSWR from 'swr';
import { useUserContext } from '@/data/UserContext';
import { DATA_KEY_PROMOCODE } from '@/data/constants/dataKey';
import { useNextRouter } from '@/data/Content/_NextRouter';
import { usePageDataFromId } from '../_PageDataFromId';
import { dataMap } from './ContentRecommendation';
import { exculdeEspotsFromCache } from '../constants/marketing';
import { expand, shrink } from '@/data/utils/keyUtil';
import { useMemo, useState } from 'react';
import { getClientSideCommon } from '@/data/utils/getClientSideCommon';
import { getESpotParams } from '../utils/getESpotQueryParams';
import { useBreadcrumbTrail } from './BreadcrumbTrail';
import useSWRMutation from 'swr/mutation';
import { ESpotContainerType } from './_ESpotDataFromName';
import { useExtraRequestParameters } from '@/data/Content/_ExtraRequestParameters';
import { fetcher } from './_ESpotDataFromNameByCategory';

export const DATA_KEY = DATA_KEY_PROMOCODE;

type GetCookieProps = {
	context: GetServerSidePropsContext;
	cookieConfig: { dataKey: string; maxAge: number; paramName: string };
	cache: Cache;
};

type GetContextProps = {
	context: GetServerSidePropsContext;
	cache: Cache;
};

type GetUpdateCacheProps = {
	context: GetServerSidePropsContext;
	cache: Cache;
	value: string | undefined;
	dataKey: string;
	hasKey: boolean;
};

export const updateCache = async ({
	context,
	cache,
	value,
	dataKey,
	hasKey,
}: GetUpdateCacheProps) => {
	if (!value && hasKey) {
		const cookie = new Cookies(context.req, context.res);
		cache.set(dataKey, cookie.get(dataKey));
	} else if (value) {
		cache.set(dataKey, value);
	}
};

const customEventFetcher =
	(pub: boolean) => async (DM_ReqCmd: string, PromoCode: string, storeId: string) => {
		try {
			return await customMarketing(pub).eventTriggerMarketing(storeId ?? '', undefined, {
				DM_ReqCmd,
				PromoCode,
			});
		} catch (e) {
			return undefined;
		}
	};

export const setGenericCookie = ({ cookieConfig, context, cache }: GetCookieProps) => {
	const { dataKey, maxAge, paramName } = cookieConfig;
	const paramValue = context.query[paramName];
	const cookieKeys = Object.keys(context.req.cookies);
	const hasKey = cookieKeys.includes(dataKey);
	const cookie = new Cookies(context.req, context.res);
	const options = { maxAge, overwrite: true, httpOnly: true };
	let valueForCache = undefined;
	if (paramValue) {
		cookie.set(dataKey, paramValue as string, options);
		valueForCache = paramValue;
	}
	updateCache({
		context,
		cache,
		value: valueForCache as string | undefined,
		dataKey: cookieConfig.dataKey,
		hasKey,
	});
};

export const setPromoCodeCookie = async ({ cookieConfig, context, cache }: GetCookieProps) => {
	const { dataKey, maxAge, paramName } = cookieConfig;
	const paramValue = context.query[paramName];
	const cookieKeys = Object.keys(context.req.cookies);
	const hasKey = cookieKeys.includes(dataKey);
	const cookie = new Cookies(context.req, context.res);
	const options = { maxAge, overwrite: true, httpOnly: false };
	let cookiePromoCodeValue = undefined;
	if (paramValue) {
		if (!hasKey) {
			cookiePromoCodeValue = paramValue;
		} else {
			const splits = cookie.get(dataKey)?.split('|');
			if (!splits?.includes(paramValue as string)) {
				cookiePromoCodeValue = `${cookie.get(dataKey)}|${paramValue}`;
			}
		}
		if (cookiePromoCodeValue) {
			cookie.set(dataKey, cookiePromoCodeValue as string, options);
		}
		updateCache({
			context,
			cache,
			value: paramValue as string | undefined,
			dataKey: cookieConfig.dataKey,
			hasKey,
		});
	}
};

export const getUrlParamCookies = async ({ context, cache }: GetContextProps) => {
	setPromoCodeCookie({ cookieConfig: PARAMETER_COOKIES[0], context, cache });
	setGenericCookie({ cookieConfig: PARAMETER_COOKIES[1], context, cache });
	setGenericCookie({ cookieConfig: PARAMETER_COOKIES[2], context, cache });
};

export const useUrlPromoCode = () => {
	const { settings } = useSettings();
	const { data: promoCode } = useSWR(PARAMETER_COOKIES[0].dataKey);
	const {user} = useUserContext();
	const { data: pageData } = usePageDataFromId();
	const { breadcrumb } = useBreadcrumbTrail();
	const params = useExtraRequestParameters();
	const router = useNextRouter();
	const { storeId, langId, defaultCatalogId: catalogId } = getClientSideCommon(settings, router);
	const [plpRibbonEspotData, setPLPRibbonEspotData] = useState<ESpotContainerType | undefined>(undefined);
	const { query } = router;
	const { PromoCode } = query; // getting promoCode from client side router
	const promoCodeValue = PromoCode as string;
	const emsName = exculdeEspotsFromCache[0];
	const queryBase = useMemo(
			() => ({ catalogId, DM_ReturnCatalogGroupId: true, DM_FilterResults: false, langId }),
			[catalogId, langId]
		);

	// PLP Ribbon Espot Api that we will call after the event api call or on PLP through trigger function
	const { trigger } = useSWRMutation(
		storeId && emsName !== '' && pageData?.tokenValue
			? [
					shrink({
						storeId,
						...getESpotParams({ pageData, query, emsName, queryBase, breadcrumb }),
						categoryId: pageData?.tokenValue,
					}),
					DATA_KEY,
				]
			: null,
		async ([props]) => {
			const expanded = expand<Record<string, any>>(props);
			return fetcher(true)(expanded.storeId, expanded.emsName, expanded?.categoryId, expanded?.query, params);
		},
	);

	useSWR(
		promoCodeValue && user
			? [
					{
						storeId,
						promoCodeValue,
					},
			  ]
			: null,

		async ([{storeId, promoCodeValue}]) =>
			customEventFetcher(true)('PromoCodeMarketingEvent', promoCodeValue, storeId), {
				onSuccess: () => {
					pageData?.layout?.name === 'PLP' ? trigger().then((resp) => setPLPRibbonEspotData(resp)): null;
				},
			}
	);

	const espotData = useMemo(() => dataMap(plpRibbonEspotData), [plpRibbonEspotData]);

	return {
		plpRibbonEspotData: espotData,
		trigger,
	};
};
